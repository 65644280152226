<template>
  <v-row class="ml-0">
    <div class="border-dashed pointer" @click="chooseImage">
      <v-img v-if="image" class="ma-3" height="51" width="51" :src="url"/>
      <v-img v-else class="ma-3" height="51" width="51" src="@/assets/images/upload_image_default.svg"/>
    </div>
    <div class="ml-3">
      <div class="openSans-200 fz-16 text-uppercase primary--text">Upload</div>
      <div class="subtitle-color fz-12 openSans-400">JPEG, PNG format only.</div>
      <div class="subtitle-color fz-12 openSans-400">Max size 2mb.</div>
    </div>
    <v-file-input id="imageUploader" class="d-none" v-model="image" accept="image/png, image/jpeg, image/bmp"></v-file-input>
  </v-row>
</template>

<script>
export default {

  name: 'UploadImageInput',

  data () {
    return {
      image: null,
    }
  },
  methods: {
    chooseImage() {
      document.getElementById("imageUploader").click()
    }
  },
  computed: {
    url() {
      if (!this.image) return;
      return URL.createObjectURL(this.image);
    }
  }
}
</script>

<style lang="css" scoped>
  .border-dashed {
    border-style: dashed;
    color: #1D8755;
    height: 80px;
    width: 80px;
  }

  .pointer {
    cursor: pointer;
  }
</style>