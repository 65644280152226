import Repository from "@/modules/common/utils/Repository";

export default {
  createAccount(accountName, logo, username, password) {
    let data = {
      "username": username,
      "password": password,
      "account_name": accountName,
      "logo": logo
    }
    return Repository.loginApi.post('accounts', data)
  }
}