import { render, staticRenderFns } from "./RegisterStepsBanner.vue?vue&type=template&id=8d2e6946&scoped=true&"
import script from "./RegisterStepsBanner.vue?vue&type=script&lang=js&"
export * from "./RegisterStepsBanner.vue?vue&type=script&lang=js&"
import style0 from "./RegisterStepsBanner.vue?vue&type=style&index=0&id=8d2e6946&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d2e6946",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VImg,VRow})
