<template>
  <div>
    <div>
      <v-form ref="form" class="mx-2" lazy-validation>
        <div :class="$vuetify.breakpoint.xs ? 'd-none' : 'd-flex'" class="justify-end mr-6 mt-6">
          <v-icon medium>mdi-help-circle-outline</v-icon>
        </div>
        <div :class="$vuetify.breakpoint.xs ? 'mobile-create-instance-container mt-2 pr-4' : 'mr-company-name mt-14'">
          <div :class="$vuetify.breakpoint.xs ? 'fz-20' : 'fz-24'" class="openSans-400" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.about.title')"></div>
          <div class="openSans-400 fz-16 mt-3 mb-4 subtitle-color" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.about.description')"></div>
          <v-text-field
            v-model="companyName"
            :label="$vuetify.lang.t('$vuetify.createInstance.labels.companyName')"
            :rules="[rules.required]" :error-messages="accountErrors"
            outlined></v-text-field>
        </div>
        <div :class="$vuetify.breakpoint.xs ? 'mobile-create-instance-container pr-4' : 'mr-company-name'">
        <div class="text-h4 d-none" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.companyLink')"></div>
        <div :class="$vuetify.breakpoint.xs ? 'fz-20 mb-4' : 'fz-24'" class="openSans-400 mb-4" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.companyLogoMessage')">
          <span class="openSans-400 fz-16 subtitle-color">{{$vuetify.lang.t('$vuetify.common.labels.optional')}} </span>
        </div>
        <upload-image-input :class="$vuetify.breakpoint.xs ? 'mt-0' : ''" />
      </div>    
      </v-form>
      <div :class="$vuetify.breakpoint.xs ? 'mobile-create-instance-container mt-10 pr-4' : 'mr-company-name mt-button'" class="d-flex justify-end">
        <v-btn filled depressed large color="primary"
               :disabled="!accountValid" @click="goToNext">{{ $vuetify.lang.t('$vuetify.common.buttons.next') }}</v-btn>
      </div>
    </div>


  </div>
</template>

<script>

import UploadImageInput from '@/modules/common/components/UploadImageInput.vue'

export default {

  name: 'CreateInstanceForm',
  components: { UploadImageInput },

  props: {
    next: Function
  },
  data () {
    return {
      companyName: '',
      accountErrors: null,
      accountValid: false,
      rules: {
        maxlength: value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
        required: value => !!value || 'Required.',
      }
    }
  },
  watch: {
    companyName() {
      this.accountValid = this.$refs.form.validate()
    }
  },
  methods: {
    goToNext() {
      if (this.$refs.form.validate()) {
        this.$emit('accountNameSet', this.companyName)
        this.next()
      }
    }
  },
  computed: {},
}
</script>

<style lang="css" scoped>

.mt-button {
  margin-top: 120px;
}

.mr-company-name {
  margin-right: 250px !important;
}

.mobile-create-instance-container {
  padding-left: 16px;
  padding-right: 45px;
}

</style>