<template>
  <v-row>
    <v-col  class="pb-0 col-12" md="4">
      <register-steps-banner :current-step="currentStep"/>
    </v-col>
    <v-col  class="col-12" offset-md="1" md="7">
      <div>
        <create-instance-form v-show="currentStep == 1" @accountNameSet="setAccountName"
                              :next="nextStep" />
        <create-account-form v-show="currentStep == 2" :next="createAccount"
                             :previous="previousStep" :error="error"/>
        <create-account-success :next="login" v-show="currentStep == 3"/>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import RegisterStepsBanner from '@/modules/login/components/RegisterStepsBanner.vue'
import CreateInstanceForm from '@/modules/login/components/CreateInstanceForm.vue'
import CreateAccountForm from '@/modules/login/components/CreateAccountForm.vue'
import CreateAccountSuccess from '@/modules/login/components/CreateAccountSuccess.vue'
import AccountService from "@/modules/login/services/AccountService";
import LoginService from "@/modules/login/services/LoginService";
import router from "@/router";

export default {

  name: 'CreateInstance',
  components: { CreateInstanceForm, CreateAccountForm, RegisterStepsBanner, CreateAccountSuccess },

  data () {
    return {
      currentStep: 1,
      accountName: null,
      logo: "logo",
      username: null,
      password: null,
      error: null
    }
  },
  methods: {
    setAccountName(accountName) {
      this.accountName = accountName
    },
    createAccount(username, password ) {
      this.username = username
      this.password = password
      AccountService.createAccount(this.accountName, this.logo, this.username, this.password)
          .then(() => this.nextStep())
          .catch(error => {
            if (error.response.data != undefined){
              this.error = error.response.data.detail
            } else {
              this.error = error
            }
          })
    },
    nextStep() {
      this.currentStep += 1
    },
    login() {
      this.error = null
      LoginService.login(this.username, this.password).then(() => {
        console.log('ok')
        router.push('/projects')
      }).catch(error => this.error = error)
    },
    previousStep() {
      this.currentStep -= 1
    }
  }
}
</script>

<style lang="css" scoped>
</style>