<template>
  <v-row>
    <v-col :class="$vuetify.breakpoint.xs ? 'mt-4 pl-8' : 'margin-sucess'">
      <v-img :class="$vuetify.breakpoint.xs ? '' : ''" class="mb-5" max-width="100" src="@/assets/images/create_instance_check.svg"/>
      <div class="openSans-400 fz-24 mb-1" v-text="$vuetify.lang.t('$vuetify.common.labels.thankYou')"></div>
      <div class="openSans-400 fz-20 mb-3" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.account.createdMessage')"></div>
      <div class="openSans-400 fz-16 mb-5 subtitle-color" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.account.instructionsMessage')"></div>
      <div class="openSans-400 fz-16 mb-6 subtitle-color" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.account.verifyMessage')"></div>
      <v-btn depressed color="primary" large @click="next">{{ $vuetify.lang.t('$vuetify.common.buttons.getStarted') }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {

  name: 'CreateAccountSuccess',
  props: {
    next: Function
  },
  data () {
    return {

    }
  },
  computed: {}
}
</script>

<style lang="css" scoped>
.margin-success {
  margin-top: 180px;
}
</style>