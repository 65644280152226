<template>
  <div :class="$vuetify.breakpoint.xs ? 'pl-4 pr-8 pt-6 pb-5 mobile-create-instance-steps-container' : 'pa-10 pr-20 create-instance-steps-container'" class="col-12">
    <div v-if="currentStep < 3">
      <v-row :class="$vuetify.breakpoint.xs ? 'pb-2' : 'pb-12'">
        <v-col>
          <div class="mb-10" justify="center">
            <v-img max-width="100" :src="require('@/assets/images/'+logo_path)"/>
          </div>
          <div :class="$vuetify.breakpoint.xs ? 'fz-22' : 'fz-32'" class="openSans-400 txt-white" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.banner.description')"></div>
        </v-col>
      </v-row>
      <v-row :class="$vuetify.breakpoint.xs ? 'mt-8 ml-0' : ''">
        <v-col :class="$vuetify.breakpoint.xs ? 'mt-0 pt-0' : ''" xs="6" md="12" class="ma-5 ml-0 pl-0">
            <div :class="$vuetify.breakpoint.xs ? 'fz-16' : 'fz-20'" class="openSans-semibold txt-white" v-if="currentStep == 1">
              <div :class="$vuetify.breakpoint.xs ? 'small-circle' : 'big-circle'" class="circle-step flex-align-center mr-8">
                <span>1</span>
              </div>
               <span>{{$vuetify.lang.t('$vuetify.createInstance.labels.banner.companyDetails')}}</span>
            </div>
            <div :class="[currentStep != 1 ? 'low-opacity' : '', $vuetify.breakpoint.xs ? 'fz-16' : 'fz-20']" class="openSans-semibold txt-white" v-else>
              <div :class="[currentStep != 1 ? 'regular-step' : 'circle-step', $vuetify.breakpoint.xs ? 'small-circle' : 'big-circle']" class="flex-align-center mr-8">
                <v-icon class="fz-20" large color="black">mdi-check</v-icon>
              </div>
               <span>{{$vuetify.lang.t('$vuetify.createInstance.labels.banner.companyDetails')}}</span>
            </div>
        </v-col>
        <v-col :class="$vuetify.breakpoint.xs ? 'mt-0 pt-0' : ''" xs="6" md="12" class="pl-0">
            <div :class="[currentStep == 1 ? 'low-opacity' : '', $vuetify.breakpoint.xs ? 'fz-16' : 'fz-20']" class="openSans-semibold txt-white">
              <div :class="[currentStep == 1 ? 'regular-step' : 'circle-step ', $vuetify.breakpoint.xs ? 'small-circle' : 'big-circle']" class="flex-align-center mr-8">
                <span>2</span>
              </div>
               <span>{{$vuetify.lang.t('$vuetify.createInstance.labels.banner.userDetails')}}</span>
            </div>
        </v-col>
      </v-row>
      <v-row>
        <div class="margin-background-image">
          <v-img v-show="currentStep == 1 && $vuetify.breakpoint.xs == false" max-width="160" src="@/assets/images/icon_bg_step_1.svg"/>
          <v-img v-show="currentStep != 1 && $vuetify.breakpoint.xs == false" max-width="160" src="@/assets/images/icon_bg_step_2.svg"/>
        </div>
      </v-row>
    </div>
    <div v-else>
      <div class="mb-10" justify="center">
        <v-img max-width="100" src="@/assets/images/logo_sorcero_white.svg"/>
      </div>
      <div :class="$vuetify.breakpoint.xs ? 'mt-6' : 'margin-sucess'" class="openSans-400 fz-32 txt-white" v-text="$vuetify.lang.t('$vuetify.createInstance.labels.banner.final')"></div>
      <v-row>
        <div class="margin-background-image">
          <v-img v-show="$vuetify.breakpoint.xs == false" max-width="160" src="@/assets/images/icon_bg_step_3.svg"/>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {

  name: 'RegisterStepsBanner',

  props: {
    currentStep: Number
  },
  data () {return {}},
  computed: {
    logo_path () {
      return (this.$vuetify.breakpoint.xs) ? 'logo_mobile.svg' : 'logo_sorcero_white.svg';
    }
  },
}
</script>

<style lang="css" scoped>

.margin-background-image {
    position: absolute;
    bottom: 3%;
    left: 21%;
}

.create-instance-steps-container {
  height: calc(100vh - 64px);
  background: linear-gradient(154deg, rgba(9,96,159,1) 0%, rgba(29,135,85,1) 100%)
}

.mobile-create-instance-steps-container {
  height: 36vh;
  background: linear-gradient(154deg, rgba(9,96,159,1) 0%, rgba(29,135,85,1) 100%)
}


.pr-20 {
  padding-right: 80px !important;
}
.circle-step {
  background-color: white;
  border: 1px solid #1d8755;
  border-radius: 50px;
  color: #000000;
}

.small-circle {
  width: 24px;
  height: 24px;
  margin-right: 100% !important;
  margin-bottom: 8px;
}

.big-circle {
  width: 40px;
  height: 40px;
}

.regular-step {
  background-color: transparent;
  border: 1px solid white;
  border-radius: 50px;
  color: #ffffff;
}

.regular-step i {
  color: white !important;
  caret-color: white !important;

}
.low-opacity {
  opacity: 0.5;
}

.flex-align-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.margin-success {
  margin-top: 50%;
}
</style>