<template>
  <div :class="$vuetify.breakpoint.xs ? 'mt-2 pr-4 pl-4' : 'pt-12'">
    <div :class="$vuetify.breakpoint.xs ? '' : 'mr-company-name mt-14'">
      <div class="openSans-400 fz-24" v-text="$vuetify.lang.t('$vuetify.login.labels.userAccount.title')"></div>
      <div class="openSans-400 fz-16 mt-3 mb-4 subtitle-color" v-text="$vuetify.lang.t('$vuetify.login.labels.userAccount.description')"></div>
      
      <v-text-field v-model="email" :label="$vuetify.lang.t('$vuetify.login.inputs.workEmail')" outlined></v-text-field>
      <v-text-field
        v-model="password"
        :label="$vuetify.lang.t('$vuetify.login.inputs.setPassword')"
        :hint="$vuetify.lang.t('$vuetify.createInstance.labels.passwordRequirements')"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showPassword ? 'text' : 'password'"
        persistent-hint outlined
        @click:append="showPassword = !showPassword">
      </v-text-field>

      <div :class="$vuetify.breakpoint.xs ? '' : 'my-5'" class="ml-3">
        <v-checkbox v-model="acceptTerms">
          <template v-slot:label>
            <span class="mr-2">
              {{ $vuetify.lang.t('$vuetify.common.labels.accept') }} <a>{{ $vuetify.lang.t('$vuetify.common.labels.termsAndConditions') }}</a>
            </span>
          </template> 
        </v-checkbox>
      </div>

<!--      <vue-recaptcha :sitekey="recaptchaSiteKey" @verify="validateRecaptcha"></vue-recaptcha>-->
      <v-alert v-if="error" color="red" dense dismissible outlined prominent text
               type="error">{{error}}</v-alert>

      <div class="d-flex justify-end my-5">
        <v-btn class="mr-10" plain color="primary" active large @click="previous">{{ $vuetify.lang.t('$vuetify.common.buttons.previousStep') }}</v-btn>
        <v-btn filled large depressed color="primary" @click="create" :disabled="!acceptTerms">{{ $vuetify.lang.t('$vuetify.common.buttons.create') }}</v-btn>
<!--        <v-btn filled large depressed color="primary" @click="create" :disabled="!acceptTerms || !isRecaptchaValid">{{ $vuetify.lang.t('$vuetify.common.buttons.create') }}</v-btn>-->
      </div>
    </div>
  </div>
</template>

<script>
// import VueRecaptcha from 'vue-recaptcha'

export default {

  name: 'CreateAccountForm',
  // components: { VueRecaptcha },

  props: {
    next: Function,
    previous: Function,
    error: String
  },
  data () {
    return {
      email: '',
      password: '',
      acceptTerms: false,
      showPassword: false,
      recaptchaSiteKey: '',
      isRecaptchaValid: false
    }
  },
  methods: {
    create() {
      this.next(this.email, this.password)
    },
    // validateRecaptcha(token) {
    //   this.isRecaptchaValid = token != null
    // }
  },
  computed: {},
  created() {
    this.recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY
  }
}
</script>

<style lang="css" scoped>
.mt-button {
  margin-top: 120px;
}

.mr-company-name {
  margin-right: 250px !important;
}
</style>